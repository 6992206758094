<template>
  <div>
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="markerLatLng" />
    </l-map>
  </div>
</template>

<script>
import {
  LMap, LTileLayer, LMarker, LPopup, LIcon,
} from 'vue2-leaflet'
import { latLng, icon, Icon } from 'leaflet'

import { BImg } from 'bootstrap-vue'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 12,
      center: [-23.5511626, -46.6470435],
      markerLatLng: [-23.5511626, -46.6470435, { draggable: 'true' }],

    }
  },
  methods: {
  },
}
</script>

<style lang="scss">

@import 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.8.0/leaflet.min.css';

.vue2leaflet-map{
  &.leaflet-container{
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}
</style>
